import * as React from "react"
import { useEffect, useState } from "react"

import { Navbar, Container, Nav } from "react-bootstrap"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faYoutube,
  faInstagram,
  faFacebookF,
} from "@fortawesome/free-brands-svg-icons"

import { AnchorLink } from "gatsby-plugin-anchor-links"
import classNames from "classnames/bind"
import Icon from "./icon"

interface HeaderProps {
  siteTitle: string
  yScroll: number
  screenWidth: number
}

const Header = ({ siteTitle, yScroll, screenWidth }: HeaderProps) => {
  var navClass = classNames({
    "bg-light": yScroll > 0,
    scrolled: yScroll > 0 || screenWidth < 992,
  })

  const [logoTextFill, setLogoTextFill] = useState("#FFFFFF");

  useEffect(() => {
    setLogoTextFill(yScroll > 0 || screenWidth < 992 ? "#000000" : "#FFFFFF");
  }, [yScroll]);

  return (
    <header>
      <Navbar fixed="top" expand="lg" className={navClass}>
        <Container>
          <Navbar.Brand>
            <Icon textFill={logoTextFill} />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="navbarScroll" />
          <Navbar.Collapse id="navbarScroll">
            <Nav className="mx-auto my-3 my-lg-0" navbarScroll>
              <AnchorLink
                className="nav-link wide text-link"
                to="#o_apartmajih"
                title="O apartmajih"
              />
              <AnchorLink
                className="nav-link wide text-link"
                to="#kontakt"
                title="Kontakt"
              />
              <AnchorLink
                className="nav-link wide text-link"
                to="#rezervacija"
                title="Rezervacija"
              />
            </Nav>
            <Nav>
              <div className="social d-flex">
                {/*<Nav.Link href="#s1">*/}
                {/*  <FontAwesomeIcon icon={faYoutube} />*/}
                {/*</Nav.Link>*/}
                <Nav.Link href="https://www.instagram.com/misemezica/" target="_blank">
                  <FontAwesomeIcon icon={faInstagram} />
                </Nav.Link>
                <Nav.Link href="https://www.facebook.com/people/MISE-apartments/100070312596885/" target="_blank">
                  <FontAwesomeIcon icon={faFacebookF} />
                </Nav.Link>
              </div>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </header>
  )
}

export default Header
