import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"

import Layout from "../components/layout"
import Seo from "../components/seo"

import { Swiper, SwiperSlide } from "swiper/react"
import "swiper/css"

import { Container, Row, Col } from "react-bootstrap"
import classNames from "classnames/bind"

import ReservationForm from "../components/reservation-form"

// Import Swiper styles
import "swiper/css"
import "swiper/css/pagination"
import "swiper/css/navigation"

// import Swiper core and required modules
import SwiperCore, { Pagination, Navigation } from "swiper"
import { useState } from "react"

import Lightbox from "react-image-lightbox"
import "react-image-lightbox/style.css"
import { useStaticQuery, graphql } from "gatsby"

// install Swiper modules
SwiperCore.use([Pagination, Navigation])

const IndexPage = () => {
  const [popupVisible, setPopupVisible] = useState(false)
  const [photoIndex, setPhotoIndex] = useState(0)
  const [isOpen, setIsOpen] = useState(false)
  const images = useStaticQuery(graphql`
    query {
      allImageSharp(
        filter: { original: { src: { regex: "/mise_app/" } } }
        sort: { fields: original___src }
      ) {
        edges {
          node {
            original {
              src
            }
          }
        }
      }
    }
  `).allImageSharp.edges.map((node: any) => {
    return node.node.original.src
  })

  var popupClass = classNames({
    open: popupVisible,
    popupNote: true,
    "d-flex": true,
    "flex-column": true,
    "justify-content-center": true,
    "align-items-center": true,
  })

  const showPopup = (e: any): void => {
    setPopupVisible(!popupVisible)
  }

  return (
    <Layout>
      <Seo title="MISE apartments" />
      <div className="banner w-100 h-100 d-flex flex-column justify-content-center align-items-center">
        <div className={popupClass}>
          <p className="trigger" onClick={e => showPopup(e)}>
            Covid obvestilo
          </p>
          <p>
            Prosimo, upoštevajte, da slovenski predpisi glede Covid-19 od
            ponudnikov nastanitev zahtevajo, da ob prihodu gostov (razen otrok,
            mlajših od 18 let z ožjim družinskim članom / skrbnikom)
            izpolnjujejo pogoje PCT (prebolevniki / cepljeni / testirani). Za
            več informacij preberite podrobnosti na povezavi. Gostje morajo
            dokazati, da so PREBOLELI COVID (zdravniško potrdilo o prebolelosti
            COVID -19 v zadnjih 6 mesecih ali dokazilo o pozitivnem testu PCR,
            starem od 10 dni - 6 mesecev), da so BILI CEPLJENII PROTI COVID
            (priznano potrdilo o cepljenju, ki prikazuje 21 dni od prvega
            odmerka zdravila Astra Zeneca / 2. odmerek Covishield, 14 dni od
            končnega odmerka drugih priznanih ponudnikov cepiv ali priznanih
            kombinacij različnih cepiv) ali da so TESTIRANI (negativen test HAG
            v 48 urah po prihodu ali negativen test PCR v 72 urah po prihodu, ki
            ga je izdal priznani organ). Če nadaljujete z rezervacijo /
            prihodom, jamčite, da imate takšno potrdilo za vse člane svoje
            skupine in da ste potrdila pripravljeni pokazati ponudniku
            nastanitve ali njegovemu zastopniku na njegovo zahtevo.
          </p>
        </div>
        <h1>Moderni apartmaji</h1>
        <h3>pod vznožjem Pece</h3>
      </div>
      <Container>
        <ReservationForm />
        <Row id="o_apartmajih" className="section about">
          <Col xs={12} md={6}>
            <div style={{ padding: "0 30px" }}>
              <StaticImage
                src="../images/about.webp"
                alt="Mise appartment"
                placeholder="blurred"
                layout="fullWidth"
              />
            </div>
          </Col>
          <Col xs={12} md={6}>
            <div style={{ padding: "0 30px" }}>
              <h2>O APARTMAJIH</h2>
              <p>
                Apartmaji Mise se nahajajo v mirnem zaledju koroške Mežice,
                daleč od vsakdanjega vrveža in le 10 minut od vznožja Pece.
              </p>
              <p>
                So odlično izhodišče za športne navdušence. Iz apartmajev se
                lahko podate na raziskovanje najlepših pohodnih in kolesarskih
                poti Koroške, obenem pa so namenjeni tudi gostom željnim
                sprostitve in miru. Odkrivate lahko tudi Mežiški rudnik in
                podzemlje Pece.
              </p>
              <p>
                Odličen razgled na okoliške hribe in gore pričara izkušnjo, ki
                je ne gre zamuditi. Na zasebni terasi vas bo objelo toplo
                koroško sonce ali hladila sproščujoča senca.
              </p>
              <p>Vabljeni.</p>
              <Row className="counters">
                <Col xs={4}>
                  <div className="counter">
                    <p className="num">5</p>
                    <p className="label">apartmajev</p>
                  </div>
                </Col>
                <Col xs={4}>
                  <div className="counter">
                    <p className="num">19</p>
                    <p className="label">ležišč</p>
                  </div>
                </Col>
                <Col xs={4}>
                  <div className="counter">
                    <p className="num">2</p>
                    <p className="label">terasi</p>
                  </div>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
        <Row className="section gallery">
          <h2>Galerija</h2>
          <Swiper
            slidesPerView={3}
            spaceBetween={0}
            slidesPerGroup={1}
            breakpoints={{
              576: {
                slidesPerView: 5,
              },
            }}
            loop={true}
            loopFillGroupWithBlank={true}
            pagination={{
              clickable: true,
            }}
            navigation={true}
            className="gallery-swiper"
          >
            <SwiperSlide>
              <StaticImage
                src="../images/mise_app_1.jpg"
                alt="Mise appartment"
                placeholder="blurred"
                height={361}
                layout="fixed"
                onClick={() => {
                  setPhotoIndex(0)
                  setIsOpen(true)
                }}
              />
            </SwiperSlide>
            <SwiperSlide>
              <StaticImage
                src="../images/mise_app_2.jpg"
                alt="Mise appartment"
                placeholder="blurred"
                height={288}
                layout="fixed"
                onClick={() => {
                  setPhotoIndex(1)
                  setIsOpen(true)
                }}
              />
            </SwiperSlide>
            <SwiperSlide>
              <StaticImage
                src="../images/mise_app_3.jpg"
                alt="Mise appartment"
                placeholder="blurred"
                height={361}
                layout="fixed"
                onClick={() => {
                  setPhotoIndex(2)
                  setIsOpen(true)
                }}
              />
            </SwiperSlide>
            <SwiperSlide>
              <StaticImage
                src="../images/mise_app_4.jpg"
                alt="Mise appartment"
                placeholder="blurred"
                height={288}
                layout="fixed"
                onClick={() => {
                  setPhotoIndex(3)
                  setIsOpen(true)
                }}
              />
            </SwiperSlide>
            <SwiperSlide>
              <StaticImage
                src="../images/mise_app_5.jpg"
                alt="Mise appartment"
                placeholder="blurred"
                height={361}
                layout="fixed"
                onClick={() => {
                  setPhotoIndex(4)
                  setIsOpen(true)
                }}
              />
            </SwiperSlide>
            <SwiperSlide>
              <StaticImage
                src="../images/mise_app_6.jpg"
                alt="Mise appartment"
                placeholder="blurred"
                height={288}
                layout="fixed"
                onClick={() => {
                  setPhotoIndex(5)
                  setIsOpen(true)
                }}
              />
            </SwiperSlide>
          </Swiper>
        </Row>
        {isOpen && (
          <Lightbox
            mainSrc={images[photoIndex]}
            nextSrc={images[(photoIndex + 1) % images.length]}
            prevSrc={images[(photoIndex + images.length - 1) % images.length]}
            onCloseRequest={() => setIsOpen(false)}
            onMovePrevRequest={() =>
              setPhotoIndex((photoIndex + images.length - 1) % images.length)
            }
            onMoveNextRequest={() =>
              setPhotoIndex((photoIndex + 1) % images.length)
            }
          />
        )}
        <Row id="kontakt" className="section location">
          <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d686.3138094568727!2d14.852507829240661!3d46.5228509214631!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x6e94c28755d28903!2zNDbCsDMxJzIyLjMiTiAxNMKwNTEnMTEuMCJF!5e0!3m2!1ssl!2ssi!4v1640610838567!5m2!1ssl!2ssi" width="600" height="450" style={{border:0}} allowFullScreen={false} loading="lazy"></iframe>
          <div className="contact">
            <h3>KJE NAS NAJDETE</h3>
            <p>
              Mariborska cesta 9<br />
              2392 Mežica
              <br />
              Slovenija
            </p>
            <hr />
            <p>
              info@mise.si
              <br />
              +386 40 512 050
            </p>
          </div>
        </Row>
      </Container>
    </Layout>
  )
}

export default IndexPage
