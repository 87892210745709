/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import * as React from "react"
import { useStaticQuery, graphql } from "gatsby"

import { useWindowScroll, useWindowSize } from "react-use"

import Header from "./header"
import Footer from "./footer"

import "bootstrap/dist/css/bootstrap.min.css"
import "../styles/styles.scss"

interface LayoutProps {
  children: React.ReactNode
}

const Layout = ({ children }: LayoutProps) => {
  const data: any = useStaticQuery(graphql`
    query MyQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  const siteTitle: string = data.site.siteMetadata?.title || `Title`

  const { x, y } = useWindowScroll()
  const { width, height } = useWindowSize()

  return (
    <>
      <Header siteTitle={siteTitle} yScroll={y} screenWidth={width} />
      {children}
      <Footer />
    </>
  )
}

export default Layout
