import * as React from "react"
import axios from "axios"

import { Form, FloatingLabel, Row, Col, Button } from "react-bootstrap"
import { useState } from "react"

const ReservationForm = () => {
  const [formData, setFormData] = useState({client_name: "", client_surname: "", client_phonenumber: "", client_email: "", client_message: ""})
  const [validated, setValidated] = useState(false)
  const [formMsg, setFormMsg] = useState("")

  let handleFormSubmit = (e: any) => {
    e.preventDefault();
    setValidated(true);

    const form = e.currentTarget;
    if (form.checkValidity() == false) {
      e.preventDefault();
      e.stopPropagation();
      return
    }

    
    axios({
      method: "post",
      url: "../../api/mail.php",
      headers: { "content-type": "application/json" },
      data: formData,
    })
      .then(result => {
        setFormMsg(result.data)
        setFormData({client_name: "", client_surname: "", client_phonenumber: "", client_email: "", client_message: ""})
        setValidated(false);
      })
      .catch(error => {
        setFormMsg("Prišlo je do napake pri pošiljanju rezervacije. Prosimo, da nas kontaktirate na info@miseapartmaji.si ali +386 40 512 050.")
        //setFormData({client_name: "", client_surname: "", client_phonenumber: "", client_email: "", client_message: ""})
      })
  }

  const handleChange = (e: any, field: string): void => {
    let value = e.target.value
    let updateValue: any = {}
    updateValue[field] = value
    setFormData({...formData, ...updateValue})
  }

  return (
    <div id="rezervacija" className="reservation-form">
      <h3>Povpraševanje</h3>
      <p>
        Vsakemu gostu se osebno posvetimo, zato vas prosimo, da nam napišete vsa
        vprašanja, želje ...
      </p>
      <Form noValidate validated={validated} onSubmit={handleFormSubmit}>
        <Row>
          <Col xs={6} md={4}>
            <FloatingLabel controlId="formName" label="Ime">
              <Form.Control
                required
                type="text"
                placeholder="Ime"
                value={formData.client_name}
                onChange={(e) => handleChange(e, "client_name")}
              />
            </FloatingLabel>
            <FloatingLabel controlId="formSurname" label="Priimek">
              <Form.Control
                required
                type="text"
                placeholder="Priimek"
                style={{ marginTop: "20px" }}
                value={formData.client_surname}
                onChange={(e) => handleChange(e, "client_surname")}
              />
            </FloatingLabel>
          </Col>
          <Col xs={6} md={4}>
            <FloatingLabel controlId="formTel" label="Telefonska št.">
              <Form.Control
                required
                type="text"
                placeholder="Telefonska št."
                value={formData.client_phonenumber}
                onChange={(e) => handleChange(e, "client_phonenumber")}
              />
            </FloatingLabel>
            <FloatingLabel controlId="formEmail" label="Elektronski naslov">
              <Form.Control
                required
                type="text"
                placeholder="Elektronski naslov"
                style={{ marginTop: "20px" }}
                value={formData.client_email}
                onChange={(e) => handleChange(e, "client_email")}
              />
            </FloatingLabel>
          </Col>
          <Col xs={12} md={4}>
            <FloatingLabel
              controlId="formMsg"
              label="Sporočilo"
              style={{ height: "100%" }}
            >
              <Form.Control
                required
                as="textarea"
                placeholder="Tukaj pustite sporočilo"
                style={{ height: "100%" }}
                value={formData.client_message}
                onChange={(e) => handleChange(e, "client_message")}
              />
            </FloatingLabel>
          </Col>
        </Row>
        <Button
          className="submitBtn"
          variant="primary"
          type="submit"
        >
          POŠLJI
        </Button>
      </Form>
      {formMsg != "" ? <p style={{margin: "0", padding: "0", marginTop: "20px"}}>{formMsg}</p> : null}
    </div>
  )
}

export default ReservationForm
