import React from 'react';

interface LogoProps {
    textFill: string
  }

const Icon = ({textFill}: LogoProps) => (
    <svg version="1.1" className="logo" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
         viewBox="0 0 160.19 170.92" xmlSpace="preserve">
    <g>
        <g>
            <path fill="#7BC248" d="M108.07,66.72L83.45,27.78c-1.48-2.35-4.9-2.35-6.39,0L52.48,66.69c-1.79,2.68-1.27,6.28,1.19,8.35
                l23.77,20.03c1.63,1.37,4.01,1.37,5.64,0l23.77-20.03C109.32,72.97,109.83,69.38,108.07,66.72z M104.92,72.75L81.51,92.47
                c-0.72,0.61-1.78,0.61-2.5,0L55.6,72.75c-1.3-1.09-1.57-2.98-0.61-4.42L79.4,29.71c0.4-0.63,1.32-0.63,1.71,0l24.43,38.65
                C106.49,69.77,106.22,71.66,104.92,72.75z"/>
            <polygon fill="#7BC248" points="77.97,67.1 70.46,60.14 57.82,71.85 60.13,73.8 70.46,64.24 75.76,69.15 72.11,72.52 74.16,74.73 
                88.06,61.85 100.9,73.74 103.21,71.79 88.06,57.76 		"/>
        </g>
        <g>
            <g>
                <path fill={textFill} d="M43.19,139.47h0.92l2.04,5.26h-0.91l-0.48-1.25h-2.22l-0.48,1.25h-0.91L43.19,139.47z M42.86,142.65h1.58
                    l-0.79-2.05L42.86,142.65z"/>
                <path fill={textFill} d="M51.63,139.47h1.79c1.04,0,1.74,0.72,1.74,1.68s-0.7,1.68-1.74,1.68h-0.89v1.89h-0.9V139.47z M54.24,141.15
                    c0-0.5-0.32-0.83-0.94-0.83h-0.77v1.65h0.77C53.92,141.98,54.24,141.65,54.24,141.15z"/>
                <path fill={textFill} d="M61.93,139.47h0.92l2.04,5.26h-0.91l-0.48-1.25h-2.22l-0.48,1.25h-0.91L61.93,139.47z M61.6,142.65h1.58
                    l-0.79-2.05L61.6,142.65z"/>
                <path fill={textFill} d="M70.37,139.47h1.73c1.04,0,1.74,0.68,1.74,1.64c0,0.79-0.42,1.39-1.17,1.58l1.31,2.04h-1.05l-1.25-1.98
                    h-0.41v1.98h-0.9V139.47z M72.93,141.11c0-0.52-0.36-0.78-0.94-0.78h-0.71v1.56h0.71C72.57,141.89,72.93,141.63,72.93,141.11z"/>
                <path fill={textFill} d="M80.63,140.33h-1.45v-0.86h3.8v0.86h-1.45v4.4h-0.9V140.33z"/>
                <path fill={textFill} d="M89.36,139.47l1.71,2.64l1.71-2.64h0.83v5.26h-0.9v-3.53l-1.64,2.5l-1.64-2.5v3.53h-0.9v-5.26H89.36z"/>
                <path fill={textFill} d="M101.14,139.47h0.92l2.04,5.26h-0.91l-0.48-1.25h-2.22l-0.48,1.25H99.1L101.14,139.47z M100.81,142.65h1.58
                    l-0.79-2.05L100.81,142.65z"/>
                <path fill={textFill} d="M111.48,139.47h0.9v3.61c0,1.1-0.68,1.74-1.8,1.74c-1.05,0-1.54-0.88-1.54-0.88l0.66-0.56
                    c0,0,0.33,0.58,0.88,0.58c0.69,0,0.89-0.45,0.89-0.86C111.48,141.37,111.48,139.47,111.48,139.47z"/>
                <path fill={textFill} d="M118.48,139.47h0.9v5.26h-0.9V139.47z"/>
            </g>
        </g>
        <g>
            <g>
                <path fill={textFill} d="M68.55,113.98h2.7v15.77h-2.7V113.98z"/>
                <path fill={textFill} d="M96.57,123.17c-2.32-0.81-4.93-2.07-4.93-5c0-2.23,1.98-4.46,5.16-4.46c3.29,0,5.14,2.5,5.14,2.5l-1.87,1.85
                    c0,0-1.31-1.78-3.27-1.78c-1.35,0-2.41,0.83-2.41,1.89c0,1.37,1.24,1.8,3.4,2.61c2.41,0.9,4.42,1.98,4.42,4.84
                    c0,2.86-2.52,4.39-5.5,4.39c-3.94,0-5.77-3.22-5.77-3.22l2.03-1.71c0,0,1.35,2.37,3.74,2.37c1.35,0,2.75-0.54,2.75-1.82
                    C99.45,124.3,98.44,123.82,96.57,123.17z"/>
                <path fill={textFill} d="M124.35,120.38h6.31v2.57h-6.31v4.23h6.98v2.57h-9.69v-15.77h9.55v2.57h-6.85V120.38z"/>
            </g>
            <polygon fill={textFill} points="48.23,113.98 45.81,113.98 40.59,121.91 35.37,113.98 32.95,113.98 29.2,129.75 31.9,129.75 
                34.75,117.79 39.03,124.28 37.77,126.2 39.95,127.63 40.59,126.66 46.43,117.79 49.28,129.75 51.98,129.75 		"/>
        </g>
    </g>
    </svg>
    
);

export default Icon;