import * as React from "react"

import { Container, Row, Col } from "react-bootstrap"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faYoutube,
  faInstagram,
  faFacebookF,
} from "@fortawesome/free-brands-svg-icons"

import { AnchorLink } from "gatsby-plugin-anchor-links"
import Icon from "./icon"

const Footer = () => (
  <footer>
    <Container>
      <Row>
        <Col xs={12} md={2} className="logoCont">
          <Icon textFill="#FFFFFF" />
        </Col>
        <Col xs={12} md={8} className="links d-flex flex-column justify-content-center">
          <div>
            <span className="link">
              <AnchorLink to="#" title="Na vrh" />
            </span>
            <span className="sep">|</span>
            <span className="link">
              <AnchorLink to="#rezervacija" title="Rezervacija" />
            </span>
            <span className="sep">|</span>
            <span className="link">
              <AnchorLink to="#o_apartmajih" title="O apartmajih" />
            </span>
            <span className="sep">|</span>
            <span className="link">
              <AnchorLink to="#kontakt" title="Kontakt" />
            </span>
          </div>
        </Col>
        <Col xs={12} md={2} className="social d-flex flex-column justify-content-center">
          <div>
          {/*<span>*/}
          {/*  <a href="#">*/}
          {/*    <FontAwesomeIcon icon={faYoutube} />*/}
          {/*  </a>*/}
          {/*</span>*/}
          <span>
            <a href="https://www.instagram.com/misemezica/" target="_blank">
              <FontAwesomeIcon icon={faInstagram} />
            </a>
          </span>
          <span>
            <a href="https://www.facebook.com/people/MISE-apartments/100070312596885/" target="_blank">
              <FontAwesomeIcon icon={faFacebookF} />
            </a>
          </span>
          </div>
        </Col>
      </Row>
    </Container>
  </footer>
)

export default Footer
